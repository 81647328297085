<template>
  <v-app-bar id="app-bar" absolute app color="white" elevation="1" dense>
    <v-btn class="mr-3" text fab small @click="setDrawer(!drawer)">
      <v-icon> mdi-menu </v-icon>
    </v-btn>

    <v-toolbar-title class="hidden-sm-and-down" v-text="$route.name" />

    <v-spacer />

    <button v-if="!showFilters && isDashboard" @click="setShowFilters(true)" class="v-btn v-btn--outlined theme--light v-size--small primary--text"><span class="v-btn__content"><i class="v-icon notranslate botao-icone v-icon--left mdi mdi-filter theme--light"></i>Mostrar Filtros</span></button>
    <button v-if="showFilters && isDashboard" @click="setShowFilters(false)" class="v-btn v-btn--outlined theme--light v-size--small primary--text"><span class="v-btn__content"><i class="v-icon notranslate botao-icone v-icon--left mdi mdi-filter theme--light"></i>Esconder Filtros</span></button>

    <div class="dropDownCoreApp">
      <b-dropdown id="dropdown-right" right variant="white" class="m-2">
        <template v-slot:button-content>
          <v-avatar size="36">
            <v-img
              v-if="usuario.imagem"
              :src="caminhoImagemUsuario + usuario.imagem"
            />
            <v-icon v-else v-text="'mdi-account'" />
          </v-avatar>
        </template>

        <v-row class="pa-4">
          <v-col cols="4">
            <v-avatar v-if="usuario" size="48">
              <v-img
                v-if="usuario.imagem"
                :src="caminhoImagemUsuario + usuario.imagem"
              />
              <v-icon v-else v-text="' mdi-account'" />
            </v-avatar>
          </v-col>
          <v-col cols="8">
            <p class="body-2">
              {{ usuario ? usuario.nome : "" }}
            </p>
          </v-col>
        </v-row>
        <v-row class="pb-3">
          <v-col cols="1" />

          <v-col cols="5" class="d-flex justify-center align-center">
            <v-btn
              color="amber accent-4"
              class="white--text"
              small
              @click="dialogAlterarImagem = true"
            >
              Alterar Imagem
            </v-btn>
          </v-col>

          <v-col cols="5" class="d-flex justify-center align-center">
            <v-btn
              color="amber accent-4"
              class="white--text"
              small
              @click="dialogAlterarSenha = true"
            >
              Alterar senha
            </v-btn>
          </v-col>
          <v-col cols="1" />
        </v-row>
        <v-divider class="ma-0" />
        <v-card-actions class="grey lighten-4 text-rigth pa-2">
          <v-spacer />
          <v-btn color="black" outlined dark small @click="logout">
            Sair
          </v-btn>
        </v-card-actions>
      </b-dropdown>
    </div>

    <v-dialog
      :value="dialogAlterarImagem"
      max-width="500px"
      @click:outside="dialogAlterarImagem = false"
    >
      <v-card>
        <v-card-title class="headline amber accent-4 white--text">
          Alterar imagem
        </v-card-title>
        <v-card-text class="pt-4 text-center">
          <v-row>
            <v-col cols="12">
                  <v-btn
                    color="amber accent-4"
                    class="white--text"
                    small
                    :loading="isSelecting"
                    @click="onButtonClick"
                  >
                    {{ buttonText }}
                  </v-btn>
                  <input
                    ref="uploader"
                    class="d-none"
                    type="file"
                    accept="image/*"
                    @change="onFileChanged"
                  />
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <v-img
                :contain="true"
                max-height="300"
                max-width="300"
                :src="imagem64"
              >
              </v-img>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="cardActions">
          <v-spacer />
          <btn-cancelar text @click="dialogAlterarImagem = false" />
          <v-btn
            color="amber accent-4"
            :loading="carregandoAlterarImagem"
            text
            @click="atualizarImagem"
          >
            <v-icon class="mr-2"> mdi-lock-reset </v-icon>
            Alterar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      :value="dialogAlterarSenha"
      max-width="500px"
      @click:outside="dialogAlterarSenha = false"
    >
      <v-card>
        <v-card-title class="headline amber accent-4 white--text">
          Alterar senha
        </v-card-title>
        <v-card-text class="pt-4 text-center">
          <v-row>
            <v-col>
              <validation-observer ref="formAlterarSenha">
                <validation-provider
                  v-slot="{ errors }"
                  vid="senha"
                  name="Nova senha"
                >
                  <v-text-field
                    v-model="novaSenha"
                    outlined
                    dense
                    hide-details="auto"
                    :disabled="carregandoAlterarSenha"
                    label="Nova senha"
                    type="password"
                    :error-messages="errors"
                    hint="Mínimo de 10 caracteres, 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caracter especial"
                    persistent-hint
                  />
                </validation-provider>
              </validation-observer>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="cardActions">
          <v-spacer />
          <btn-cancelar text @click="dialogAlterarSenha = false" />
          <v-btn
            color="amber accent-4"
            :loading="carregandoAlterarSenha"
            text
            @click="alterarSenha"
          >
            <v-icon class="mr-2"> mdi-lock-reset </v-icon>
            Alterar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import usuarios from "@/api/usuarios";
import store from "@/store";
import autenticacao from "@/api/autenticacao";

export default {
  data: () => ({
    selectedFile: null,
    defaultButtonText: "Alterar Imagem",
    isSelecting: false,
    dialogAlterarImagem: false,
    dialogAlterarSenha: false,
    carregandoAlterarSenha: false,
    carregandoAlterarImagem: false,
    carregandoBuscar: false,
    novaSenha: null,
    imagemLogo: null,
    imagem: null,
    imagem_logo: null,
    imagem64: null,
    caminhoImagemUsuario:
      process.env.VUE_APP_URL_SERVIDOR + "storage/imagens_usuario/",
  }),

  computed: {
    ...mapState("layout", ["drawer"]),
    ...mapState("usuario", ["usuario"]),
    ...mapState('filters', ['showFilters']),

    isDashboard() {
      return this.$route.name == 'Dashboard'
    },

    buttonText() {
      return this.selectedFile
        ? this.selectedFile.name
        : this.defaultButtonText;
    },

    novaImagem() {
      return this.$store.state.usuario.usuario;
    },
  },

  methods: {
    ...mapActions('filters', ['setShowFilters']),

    ...mapMutations({
      setDrawer: "layout/SET_DRAWER",
    }),
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    },
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.selectedFile = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        this.imagem64 = reader.result;
      };
      reader.readAsDataURL(this.selectedFile);
      console.log(this.selectedFile, "READER");
    },
    async atualizarImagem() {
      try {
        this.carregandoAlterarImagem = true;
        const idUsuario = this.$store.state.usuario.usuario.id;
        const dados = {
          imagem_usuario: this.imagem64,
        };
        await usuarios.atualizarImagem(idUsuario, dados);
      
        this.$snackbar.mostrar({
          cor: "success",
          mensagem: this.$mensagens._("sucesso_alterar_imagem"),
        });
      } catch (e) {
        if (e.response.status === 422) {
          this.$refs.setErrors(this.$erroApi.validacao(e));
          return;
        }

        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
      } finally {
        const resposta = await autenticacao.me();
        const imagemNova = resposta.data.imagem_usuario;
        this.$store.dispatch("atualizarImagem", imagemNova);

        this.carregandoAlterarImagem = false;
        this.dialogAlterarImagem = false;
      }
    },

    async alterarSenha() {
      try {
        this.carregandoAlterarSenha = true;
        this.$refs.formAlterarSenha.setErrors({});
        await usuarios.alterarSenha(store.state.usuario.usuario.id, {
          senha: this.novaSenha,
        });

        this.dialogAlterarSenha = false;
        this.$snackbar.mostrar({
          cor: "success",
          mensagem: this.$mensagens._("sucesso_alterar_senha"),
        });
      } catch (e) {
        if (e.response.status === 422) {
          this.$refs.formAlterarSenha.setErrors(this.$erroApi.validacao(e));
          return;
        }

        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
      } finally {
        this.carregandoAlterarSenha = false;
      }
    },
  },
};
</script>
<style>
.dropDownCoreApp .dropdown-toggle::after {
  display: none !important;
}
.cardActions {
  border-radius: 5px;
}
.dropDownCoreApp .dropdown-menu {
  padding: 0 !important;
  transform: translate3d(-204px, 44px, 0px) !important;
  min-width: 20rem !important;
  border-radius: 5px;
}
.dropDownCoreApp .btn-group > .btn:focus {
  box-shadow: 0 0 0 0 !important;
}
#app-bar {
  z-index: 2;
}
</style>